<template>
  <div class="text-xs mt-1 ml-2 font-bold">
    <div v-if="note !== null">
      {{ note }}
    </div>
    <div v-else>
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionNote',
  components: {},
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loadingCount: 0,
      note: null,
    };
  },
  methods: {
    fetchNote() {
      this.note = null;
      if (!this.id) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/select/option/${this.id}/note`)
        .then((res) => {
          this.note = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetchNote();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
